// @generated by protoc-gen-connect-es v1.1.3 with parameter "target=ts"
// @generated from file avn/connect/v1/dimensions.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateDimensionRequest, CreateDimensionResponse, DimensionEvent, DimensionInstance, GetDimensionRequest, JoinDimensionRequest, SetLessonContextRequest, SetLessonContextResponse } from "./dimensions_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service avn.connect.v1.DimensionService
 */
export const DimensionService = {
  typeName: "avn.connect.v1.DimensionService",
  methods: {
    /**
     * @generated from rpc avn.connect.v1.DimensionService.CreateDimension
     */
    createDimension: {
      name: "CreateDimension",
      I: CreateDimensionRequest,
      O: CreateDimensionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc avn.connect.v1.DimensionService.GetDimension
     */
    getDimension: {
      name: "GetDimension",
      I: GetDimensionRequest,
      O: DimensionInstance,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc avn.connect.v1.DimensionService.JoinDimension
     */
    joinDimension: {
      name: "JoinDimension",
      I: JoinDimensionRequest,
      O: DimensionEvent,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * @generated from rpc avn.connect.v1.DimensionService.SetLessonContext
     */
    setLessonContext: {
      name: "SetLessonContext",
      I: SetLessonContextRequest,
      O: SetLessonContextResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

