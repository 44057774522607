// @generated by protoc-gen-es v1.4.2 with parameter "target=ts"
// @generated from file avn/connect/v1/dimensions.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { ClientCredentials } from "./clients_pb.js";
import { Authorization, IdentityProvider } from "./authorization_pb.js";
import { ConnectionCredentials, ConnectionInstance } from "./connections_pb.js";
import { PresenceUpdate } from "./presence_pb.js";
import { LessonContext } from "./lesson_context_pb.js";
import { OperationState } from "./operations_pb.js";
import { AvailableContent, UserInterfaceFeatures } from "./features_pb.js";
import { InteractionPermissions } from "./interaction_permissions_pb.js";
import { LicenseInfo } from "./licenses_pb.js";
import { PartnerSession } from "./partners_pb.js";

/**
 * @generated from enum avn.connect.v1.ExpiryStrategy
 */
export enum ExpiryStrategy {
  /**
   * @generated from enum value: EXPIRY_STRATEGY_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: EXPIRY_STRATEGY_ANY_CONNECTION = 1;
   */
  ANY_CONNECTION = 1,

  /**
   * @generated from enum value: EXPIRY_STRATEGY_ONLY_OWNER = 2;
   */
  ONLY_OWNER = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(ExpiryStrategy)
proto3.util.setEnumType(ExpiryStrategy, "avn.connect.v1.ExpiryStrategy", [
  { no: 0, name: "EXPIRY_STRATEGY_UNSPECIFIED" },
  { no: 1, name: "EXPIRY_STRATEGY_ANY_CONNECTION" },
  { no: 2, name: "EXPIRY_STRATEGY_ONLY_OWNER" },
]);

/**
 * @generated from message avn.connect.v1.CreateDimensionRequest
 */
export class CreateDimensionRequest extends Message<CreateDimensionRequest> {
  /**
   * The client that is making the request
   *
   * @generated from field: avn.connect.v1.ClientCredentials client = 1;
   */
  client?: ClientCredentials;

  /**
   * Authorization for the dimension creator
   *
   * @generated from field: optional avn.connect.v1.Authorization auth = 2;
   */
  auth?: Authorization;

  /**
   * A hall pass to use for additional licensing
   *
   * @generated from field: optional string pass_id = 3;
   */
  passId?: string;

  /**
   * Which Eduverse cloud domain to use?
   *
   * @generated from field: optional string preferred_domain = 4;
   */
  preferredDomain?: string;

  /**
   * Where is the request coming from? (typically a hostname)
   *
   * @generated from field: optional string referrer = 5;
   */
  referrer?: string;

  /**
   * Narrow dimension license scope to a specific organization
   *
   * @generated from field: optional int32 context_organization_id = 6;
   */
  contextOrganizationId?: number;

  constructor(data?: PartialMessage<CreateDimensionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.CreateDimensionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "client", kind: "message", T: ClientCredentials },
    { no: 2, name: "auth", kind: "message", T: Authorization, opt: true },
    { no: 3, name: "pass_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "preferred_domain", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "referrer", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "context_organization_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateDimensionRequest {
    return new CreateDimensionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateDimensionRequest {
    return new CreateDimensionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateDimensionRequest {
    return new CreateDimensionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateDimensionRequest | PlainMessage<CreateDimensionRequest> | undefined, b: CreateDimensionRequest | PlainMessage<CreateDimensionRequest> | undefined): boolean {
    return proto3.util.equals(CreateDimensionRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.CreateDimensionResponse
 */
export class CreateDimensionResponse extends Message<CreateDimensionResponse> {
  /**
   * @generated from field: string dimension_id = 1;
   */
  dimensionId = "";

  constructor(data?: PartialMessage<CreateDimensionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.CreateDimensionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dimension_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateDimensionResponse {
    return new CreateDimensionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateDimensionResponse {
    return new CreateDimensionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateDimensionResponse {
    return new CreateDimensionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateDimensionResponse | PlainMessage<CreateDimensionResponse> | undefined, b: CreateDimensionResponse | PlainMessage<CreateDimensionResponse> | undefined): boolean {
    return proto3.util.equals(CreateDimensionResponse, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GetDimensionRequest
 */
export class GetDimensionRequest extends Message<GetDimensionRequest> {
  /**
   * @generated from field: avn.connect.v1.ClientCredentials client = 1;
   */
  client?: ClientCredentials;

  /**
   * @generated from field: avn.connect.v1.Authorization auth = 2;
   */
  auth?: Authorization;

  /**
   * @generated from field: string dimension_id = 3;
   */
  dimensionId = "";

  constructor(data?: PartialMessage<GetDimensionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetDimensionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "client", kind: "message", T: ClientCredentials },
    { no: 2, name: "auth", kind: "message", T: Authorization },
    { no: 3, name: "dimension_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetDimensionRequest {
    return new GetDimensionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetDimensionRequest {
    return new GetDimensionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetDimensionRequest {
    return new GetDimensionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetDimensionRequest | PlainMessage<GetDimensionRequest> | undefined, b: GetDimensionRequest | PlainMessage<GetDimensionRequest> | undefined): boolean {
    return proto3.util.equals(GetDimensionRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.JoinDimensionRequest
 */
export class JoinDimensionRequest extends Message<JoinDimensionRequest> {
  /**
   * @generated from field: avn.connect.v1.ClientCredentials client = 1;
   */
  client?: ClientCredentials;

  /**
   * @generated from field: avn.connect.v1.Authorization auth = 2;
   */
  auth?: Authorization;

  /**
   * @generated from field: string dimension_id = 3;
   */
  dimensionId = "";

  constructor(data?: PartialMessage<JoinDimensionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.JoinDimensionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "client", kind: "message", T: ClientCredentials },
    { no: 2, name: "auth", kind: "message", T: Authorization },
    { no: 3, name: "dimension_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): JoinDimensionRequest {
    return new JoinDimensionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): JoinDimensionRequest {
    return new JoinDimensionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): JoinDimensionRequest {
    return new JoinDimensionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: JoinDimensionRequest | PlainMessage<JoinDimensionRequest> | undefined, b: JoinDimensionRequest | PlainMessage<JoinDimensionRequest> | undefined): boolean {
    return proto3.util.equals(JoinDimensionRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.DimensionEvent
 */
export class DimensionEvent extends Message<DimensionEvent> {
  /**
   * Status of the connected dimension
   *
   * @generated from field: optional avn.connect.v1.DimensionStatus status = 1;
   */
  status?: DimensionStatus;

  /**
   * Immutable info about the dimension
   *
   * @generated from field: optional avn.connect.v1.DimensionInfo info = 2;
   */
  info?: DimensionInfo;

  /**
   * Connection details including credentials for future calls
   *
   * @generated from field: optional avn.connect.v1.ConnectionInstance connection = 3;
   */
  connection?: ConnectionInstance;

  /**
   * Broadcast message to display
   *
   * @generated from field: optional avn.connect.v1.DimensionBroadcast broadcast = 4;
   */
  broadcast?: DimensionBroadcast;

  /**
   * Track people joinging and leaving the dimension
   *
   * @generated from field: optional avn.connect.v1.PresenceUpdate presence = 5;
   */
  presence?: PresenceUpdate;

  /**
   * Track lesson context changes (gather and look)
   *
   * @generated from field: optional avn.connect.v1.LessonContext lesson = 6;
   */
  lesson?: LessonContext;

  constructor(data?: PartialMessage<DimensionEvent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.DimensionEvent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "message", T: DimensionStatus, opt: true },
    { no: 2, name: "info", kind: "message", T: DimensionInfo, opt: true },
    { no: 3, name: "connection", kind: "message", T: ConnectionInstance, opt: true },
    { no: 4, name: "broadcast", kind: "message", T: DimensionBroadcast, opt: true },
    { no: 5, name: "presence", kind: "message", T: PresenceUpdate, opt: true },
    { no: 6, name: "lesson", kind: "message", T: LessonContext, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DimensionEvent {
    return new DimensionEvent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DimensionEvent {
    return new DimensionEvent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DimensionEvent {
    return new DimensionEvent().fromJsonString(jsonString, options);
  }

  static equals(a: DimensionEvent | PlainMessage<DimensionEvent> | undefined, b: DimensionEvent | PlainMessage<DimensionEvent> | undefined): boolean {
    return proto3.util.equals(DimensionEvent, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.DimensionStatus
 */
export class DimensionStatus extends Message<DimensionStatus> {
  /**
   * @generated from field: avn.connect.v1.OperationState state = 1;
   */
  state = OperationState.UNSPECIFIED;

  /**
   * @generated from field: optional string detail = 2;
   */
  detail?: string;

  constructor(data?: PartialMessage<DimensionStatus>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.DimensionStatus";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "state", kind: "enum", T: proto3.getEnumType(OperationState) },
    { no: 2, name: "detail", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DimensionStatus {
    return new DimensionStatus().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DimensionStatus {
    return new DimensionStatus().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DimensionStatus {
    return new DimensionStatus().fromJsonString(jsonString, options);
  }

  static equals(a: DimensionStatus | PlainMessage<DimensionStatus> | undefined, b: DimensionStatus | PlainMessage<DimensionStatus> | undefined): boolean {
    return proto3.util.equals(DimensionStatus, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.DimensionInstance
 */
export class DimensionInstance extends Message<DimensionInstance> {
  /**
   * @generated from field: avn.connect.v1.DimensionInfo info = 1;
   */
  info?: DimensionInfo;

  /**
   * @generated from field: avn.connect.v1.AvailableContent content = 2;
   */
  content?: AvailableContent;

  /**
   * @generated from field: avn.connect.v1.InteractionPermissions permissions = 4;
   */
  permissions?: InteractionPermissions;

  /**
   * @generated from field: avn.connect.v1.UserInterfaceFeatures features = 5;
   */
  features?: UserInterfaceFeatures;

  constructor(data?: PartialMessage<DimensionInstance>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.DimensionInstance";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "info", kind: "message", T: DimensionInfo },
    { no: 2, name: "content", kind: "message", T: AvailableContent },
    { no: 4, name: "permissions", kind: "message", T: InteractionPermissions },
    { no: 5, name: "features", kind: "message", T: UserInterfaceFeatures },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DimensionInstance {
    return new DimensionInstance().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DimensionInstance {
    return new DimensionInstance().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DimensionInstance {
    return new DimensionInstance().fromJsonString(jsonString, options);
  }

  static equals(a: DimensionInstance | PlainMessage<DimensionInstance> | undefined, b: DimensionInstance | PlainMessage<DimensionInstance> | undefined): boolean {
    return proto3.util.equals(DimensionInstance, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.DimensionInfo
 */
export class DimensionInfo extends Message<DimensionInfo> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: google.protobuf.Timestamp created = 3;
   */
  created?: Timestamp;

  /**
   * @generated from field: avn.connect.v1.ExpiryStrategy expiry_strategy = 4;
   */
  expiryStrategy = ExpiryStrategy.UNSPECIFIED;

  /**
   * @generated from field: string domain = 5;
   */
  domain = "";

  /**
   * @generated from field: optional string pass_id = 6;
   */
  passId?: string;

  /**
   * @generated from field: avn.connect.v1.AccessLimits access_limits = 7;
   */
  accessLimits?: AccessLimits;

  /**
   * @generated from field: avn.connect.v1.DimensionOrigin origin = 8;
   */
  origin?: DimensionOrigin;

  /**
   * @generated from field: repeated avn.connect.v1.LicenseInfo licenses = 9;
   */
  licenses: LicenseInfo[] = [];

  /**
   * @generated from field: optional string entry_message = 10;
   */
  entryMessage?: string;

  constructor(data?: PartialMessage<DimensionInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.DimensionInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "created", kind: "message", T: Timestamp },
    { no: 4, name: "expiry_strategy", kind: "enum", T: proto3.getEnumType(ExpiryStrategy) },
    { no: 5, name: "domain", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "pass_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "access_limits", kind: "message", T: AccessLimits },
    { no: 8, name: "origin", kind: "message", T: DimensionOrigin },
    { no: 9, name: "licenses", kind: "message", T: LicenseInfo, repeated: true },
    { no: 10, name: "entry_message", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DimensionInfo {
    return new DimensionInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DimensionInfo {
    return new DimensionInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DimensionInfo {
    return new DimensionInfo().fromJsonString(jsonString, options);
  }

  static equals(a: DimensionInfo | PlainMessage<DimensionInfo> | undefined, b: DimensionInfo | PlainMessage<DimensionInfo> | undefined): boolean {
    return proto3.util.equals(DimensionInfo, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.DimensionOrigin
 */
export class DimensionOrigin extends Message<DimensionOrigin> {
  /**
   * @generated from field: optional string client_id = 1;
   */
  clientId?: string;

  /**
   * @generated from field: optional int32 user_id = 2;
   */
  userId?: number;

  /**
   * @generated from field: optional avn.connect.v1.PartnerSession partner_session = 3;
   */
  partnerSession?: PartnerSession;

  constructor(data?: PartialMessage<DimensionOrigin>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.DimensionOrigin";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "client_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "user_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "partner_session", kind: "message", T: PartnerSession, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DimensionOrigin {
    return new DimensionOrigin().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DimensionOrigin {
    return new DimensionOrigin().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DimensionOrigin {
    return new DimensionOrigin().fromJsonString(jsonString, options);
  }

  static equals(a: DimensionOrigin | PlainMessage<DimensionOrigin> | undefined, b: DimensionOrigin | PlainMessage<DimensionOrigin> | undefined): boolean {
    return proto3.util.equals(DimensionOrigin, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.DimensionBroadcast
 */
export class DimensionBroadcast extends Message<DimensionBroadcast> {
  /**
   * @generated from field: string message = 1;
   */
  message = "";

  /**
   * @generated from field: optional string announcer_connection_id = 2;
   */
  announcerConnectionId?: string;

  constructor(data?: PartialMessage<DimensionBroadcast>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.DimensionBroadcast";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "announcer_connection_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DimensionBroadcast {
    return new DimensionBroadcast().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DimensionBroadcast {
    return new DimensionBroadcast().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DimensionBroadcast {
    return new DimensionBroadcast().fromJsonString(jsonString, options);
  }

  static equals(a: DimensionBroadcast | PlainMessage<DimensionBroadcast> | undefined, b: DimensionBroadcast | PlainMessage<DimensionBroadcast> | undefined): boolean {
    return proto3.util.equals(DimensionBroadcast, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.AccessLimits
 */
export class AccessLimits extends Message<AccessLimits> {
  /**
   * @generated from field: int32 room_capacity = 1;
   */
  roomCapacity = 0;

  /**
   * @generated from field: int32 dimension_capacity = 2;
   */
  dimensionCapacity = 0;

  /**
   * @generated from field: repeated avn.connect.v1.IdentityProvider identity_providers = 3;
   */
  identityProviders: IdentityProvider[] = [];

  /**
   * @generated from field: repeated string email_whitelist = 4;
   */
  emailWhitelist: string[] = [];

  constructor(data?: PartialMessage<AccessLimits>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.AccessLimits";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "room_capacity", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "dimension_capacity", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "identity_providers", kind: "enum", T: proto3.getEnumType(IdentityProvider), repeated: true },
    { no: 4, name: "email_whitelist", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AccessLimits {
    return new AccessLimits().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AccessLimits {
    return new AccessLimits().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AccessLimits {
    return new AccessLimits().fromJsonString(jsonString, options);
  }

  static equals(a: AccessLimits | PlainMessage<AccessLimits> | undefined, b: AccessLimits | PlainMessage<AccessLimits> | undefined): boolean {
    return proto3.util.equals(AccessLimits, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.SetLessonContextRequest
 */
export class SetLessonContextRequest extends Message<SetLessonContextRequest> {
  /**
   * @generated from field: avn.connect.v1.ConnectionCredentials credentials = 1;
   */
  credentials?: ConnectionCredentials;

  /**
   * @generated from field: string dimension_id = 2;
   */
  dimensionId = "";

  /**
   * @generated from field: optional avn.connect.v1.LessonContext context = 3;
   */
  context?: LessonContext;

  constructor(data?: PartialMessage<SetLessonContextRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.SetLessonContextRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "credentials", kind: "message", T: ConnectionCredentials },
    { no: 2, name: "dimension_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "context", kind: "message", T: LessonContext, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetLessonContextRequest {
    return new SetLessonContextRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetLessonContextRequest {
    return new SetLessonContextRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SetLessonContextRequest {
    return new SetLessonContextRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SetLessonContextRequest | PlainMessage<SetLessonContextRequest> | undefined, b: SetLessonContextRequest | PlainMessage<SetLessonContextRequest> | undefined): boolean {
    return proto3.util.equals(SetLessonContextRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.SetLessonContextResponse
 */
export class SetLessonContextResponse extends Message<SetLessonContextResponse> {
  /**
   * @generated from field: avn.connect.v1.OperationState state = 1;
   */
  state = OperationState.UNSPECIFIED;

  /**
   * @generated from field: optional string detail = 2;
   */
  detail?: string;

  constructor(data?: PartialMessage<SetLessonContextResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.SetLessonContextResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "state", kind: "enum", T: proto3.getEnumType(OperationState) },
    { no: 2, name: "detail", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetLessonContextResponse {
    return new SetLessonContextResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetLessonContextResponse {
    return new SetLessonContextResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SetLessonContextResponse {
    return new SetLessonContextResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SetLessonContextResponse | PlainMessage<SetLessonContextResponse> | undefined, b: SetLessonContextResponse | PlainMessage<SetLessonContextResponse> | undefined): boolean {
    return proto3.util.equals(SetLessonContextResponse, a, b);
  }
}

